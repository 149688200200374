.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1400px;
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 16px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1080px;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .root > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-width: 0;
    margin-left: calc(0px - 16px);
    width: calc(100% + 16px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 16px;
    margin-top: 16px;
  }
}
.link__v3Bjc {
  position: relative;
  display: flex;
}
.img {
  object-fit: contain;
  width: 9em;
  height: 48px;
  position: relative;
  margin-top: -2px;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: contain;
}
.freeBox___5HpXd {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox___5HpXd > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox___5HpXd > :global(.__wab_flex-container) > *,
.freeBox___5HpXd > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___5HpXd > :global(.__wab_flex-container) > picture > img,
.freeBox___5HpXd
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___5HpXd {
    display: flex;
    position: relative;
    flex-direction: row;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___5HpXd > :global(.__wab_flex-container) {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___5HpXd > :global(.__wab_flex-container) > *,
  .freeBox___5HpXd > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___5HpXd > :global(.__wab_flex-container) > picture > img,
  .freeBox___5HpXd
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 8px;
  }
}
.navLink___6SIji:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .navLink___6SIji:global(.__wab_instance) {
    display: none;
  }
}
.text__v5Efs {
  height: auto;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
}
.navLink__l1Tgw:global(.__wab_instance) {
  position: relative;
}
.text__lcpNx {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
}
.navLink__uaMnB:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .navLink__uaMnB:global(.__wab_instance) {
    display: none;
  }
}
.text__zs28Q {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
}
.navLink__fwqv1:global(.__wab_instance) {
  position: relative;
}
.text__dsTYr {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
}
.greenButton3 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: auto;
  height: auto;
  max-width: 100%;
  background: #136f27;
  align-self: center;
  color: #ffffff;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.3s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  border-radius: 4px;
  padding: 6px 14px;
}
.root .greenButton3:hover {
  color: #ffffff;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  background: linear-gradient(90deg, #136f27 50%, #168d30 100%);
}
.text__kJaJr {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 16px;
}
.freeBox__uGtD7 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.button {
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  font-weight: 500;
  background: #000000d9;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  border-radius: 6px;
  border-width: 0px;
}
.link__n2EL {
  position: relative;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  -webkit-transition-property: all;
  -webkit-transition-timing-function: ease-out;
  -webkit-transition-duration: 0.5s;
  border-radius: 6px;
  padding: 10px 16px;
  border: 1px solid transparent;
}
.link__n2EL > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.link__n2EL > :global(.__wab_flex-container) > *,
.link__n2EL > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.link__n2EL > :global(.__wab_flex-container) > picture > img,
.link__n2EL
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.svg__zhQtz {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
.text___5E8UU {
  font-weight: 400;
  color: #05050599;
  font-size: 18px;
  line-height: 26px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.svg__vhcug {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
