.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  background: url("./images/lines3Png46Dab54C328A6A5695AaPng.png") right 0% top 0% / cover no-repeat,
    #161620;
  min-width: 0;
  min-height: 0;
  padding: 20px 24px 96px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root {
    background: #161620;
  }
}
.navBar2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.hero {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 90vh;
  max-width: 1400px;
  background: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .hero {
    margin-bottom: 70px;
    height: 100vh;
    margin-top: 70px;
    flex-shrink: 0;
  }
}
.columns__pjHh {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__pjHh > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 40px);
  width: calc(100% + 40px);
  --plsmc-rc-col-gap: 40px;
}
.columns__pjHh > :global(.__wab_flex-container) > *,
.columns__pjHh > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__pjHh > :global(.__wab_flex-container) > picture > img,
.columns__pjHh
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 40px;
}
.columns__pjHh > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 7 / 12);
}
.columns__pjHh > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 5 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__pjHh {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__pjHh > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
    margin-top: calc(0px - 30px);
    height: calc(100% + 30px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__pjHh > :global(.__wab_flex-container) > *,
  .columns__pjHh > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__pjHh > :global(.__wab_flex-container) > picture > img,
  .columns__pjHh
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 30px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__pjHh > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.heroImgCol {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.heroCityImg:global(.__wab_instance) {
  position: relative;
}
.column__fCWs1 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.form:global(.__wab_instance) {
  position: relative;
  align-self: flex-start;
}
.logos {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  align-self: auto;
  margin-top: 0px;
  max-width: 800px;
  align-content: stretch;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .logos {
    width: 100%;
    min-width: 0;
  }
}
.reveal__isHxm:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  align-self: center;
}
.text__lvAh1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  align-self: center;
  min-width: 0;
}
.logosGroup {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  width: auto;
  flex-grow: 1;
  align-self: auto;
  flex-shrink: 1;
  margin-top: 5px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .logosGroup {
    left: auto;
    top: auto;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .logosGroup > :global(.__wab_flex-container) {
    margin-left: calc(0px - 5px);
    width: calc(100% + 5px);
    margin-top: calc(0px - 5px);
    height: calc(100% + 5px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .logosGroup > :global(.__wab_flex-container) > *,
  .logosGroup > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .logosGroup > :global(.__wab_flex-container) > picture > img,
  .logosGroup
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 5px;
    margin-top: 5px;
  }
}
.columns__yOzdN {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  max-width: 900px;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__yOzdN > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
}
.columns__yOzdN > :global(.__wab_flex-container) > :nth-child(4n + 1) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns__yOzdN > :global(.__wab_flex-container) > :nth-child(4n + 2) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns__yOzdN > :global(.__wab_flex-container) > :nth-child(4n + 3) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns__yOzdN > :global(.__wab_flex-container) > :nth-child(4n + 4) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__yOzdN {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__yOzdN > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
    margin-left: calc(0px - 10px);
    width: calc(100% + 10px);
    --plsmc-rc-col-gap: 10px;
    margin-top: calc(0px - 10px);
    height: calc(100% + 10px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__yOzdN > :global(.__wab_flex-container) > *,
  .columns__yOzdN > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__yOzdN > :global(.__wab_flex-container) > picture > img,
  .columns__yOzdN
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 10px;
    margin-top: 10px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__yOzdN > :global(.__wab_flex-container) > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__yOzdN > :global(.__wab_flex-container) > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
.column__cMkNo {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__dUjQ {
  height: auto;
  object-fit: contain;
  flex-shrink: 1;
  max-height: 100px;
  width: 100%;
  max-width: 100px;
  position: relative;
  left: auto;
  top: auto;
  min-width: 0;
}
.img__dUjQ > picture > img {
  object-fit: contain;
}
.column__hVCxi {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__qZx {
  height: auto;
  object-fit: contain;
  flex-shrink: 1;
  max-height: 100px;
  width: 100%;
  max-width: 100px;
  position: relative;
  left: auto;
  top: auto;
  min-width: 0;
}
.img__qZx > picture > img {
  object-fit: contain;
}
.column__oDJlW {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__zTm0R {
  height: auto;
  object-fit: contain;
  flex-shrink: 1;
  max-height: 100px;
  width: 100%;
  max-width: 100px;
  position: relative;
  left: auto;
  top: auto;
  min-width: 0;
}
.img__zTm0R > picture > img {
  object-fit: contain;
}
.column___9IpI9 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__t2T3A {
  height: auto;
  object-fit: contain;
  flex-shrink: 1;
  max-height: 100px;
  width: 100%;
  max-width: 100px;
  position: relative;
  left: auto;
  top: auto;
  min-width: 0;
}
.img__t2T3A > picture > img {
  object-fit: contain;
}
.column__dc5AD {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__eHm0A {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 15px;
}
.img___6GAyz {
  height: auto;
  object-fit: contain;
  flex-shrink: 1;
  max-height: 100px;
  width: 100%;
  max-width: 100px;
  position: relative;
  left: auto;
  top: auto;
  min-width: 0;
}
.img___6GAyz > picture > img {
  object-fit: contain;
}
.column__pxtgQ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__zOaDs {
  height: auto;
  object-fit: contain;
  flex-shrink: 1;
  max-height: 100px;
  width: 100%;
  max-width: 100px;
  position: relative;
  left: auto;
  top: auto;
  min-width: 0;
}
.img__zOaDs > picture > img {
  object-fit: contain;
}
.column__lm5DX {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__yvbT3 {
  height: auto;
  object-fit: contain;
  flex-shrink: 1;
  max-height: 100px;
  width: 100%;
  max-width: 100px;
  position: relative;
  left: auto;
  top: auto;
  min-width: 0;
}
.img__yvbT3 > picture > img {
  object-fit: contain;
}
.column__xdnNg {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__piGuG {
  height: auto;
  object-fit: contain;
  flex-shrink: 1;
  max-height: 100px;
  width: 100%;
  max-width: 100px;
  position: relative;
  left: auto;
  top: auto;
  min-width: 0;
}
.img__piGuG > picture > img {
  object-fit: contain;
}
.dots {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  margin-top: 68px;
  max-width: 1100px;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.dots > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 40px);
  width: calc(100% + 40px);
  --plsmc-rc-col-gap: 40px;
}
.dots > :global(.__wab_flex-container) > *,
.dots > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.dots > :global(.__wab_flex-container) > picture > img,
.dots > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 40px;
}
.dots > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.dots > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .dots {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .dots > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
    margin-top: calc(0px - 15px);
    height: calc(100% + 15px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .dots > :global(.__wab_flex-container) > *,
  .dots > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .dots > :global(.__wab_flex-container) > picture > img,
  .dots
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 15px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .dots > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__c3VuA {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.dotsImg:global(.__wab_instance) {
  position: relative;
}
.column__bWm75 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  padding: 8px;
}
.reveal__o3ZM2:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.h2__l2Ki {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 2em;
  font-weight: 900;
  color: #ffffff;
  min-width: 0;
}
.text__m18Q9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 600px;
  font-size: 1em;
  font-weight: 300;
  margin-top: 10px;
  min-width: 0;
}
.data {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 1100px;
  background: none;
  margin-top: 57px;
  min-width: 0;
  padding: 8px;
}
.text__x0Vfk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  align-self: center;
  min-width: 0;
}
.sentimentMovement {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.sentimentMovement > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  --plsmc-rc-col-gap: 0px;
}
.sentimentMovement > :global(.__wab_flex-container) > *,
.sentimentMovement > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.sentimentMovement > :global(.__wab_flex-container) > picture > img,
.sentimentMovement
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.sentimentMovement > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.sentimentMovement > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .sentimentMovement {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .sentimentMovement > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
    margin-top: calc(0px - 20px);
    height: calc(100% + 20px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .sentimentMovement > :global(.__wab_flex-container) > *,
  .sentimentMovement
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .sentimentMovement > :global(.__wab_flex-container) > picture > img,
  .sentimentMovement
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 20px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .sentimentMovement > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column___6Wkt9 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__hhKYv {
  position: relative;
  object-fit: contain;
  width: 100%;
  max-width: 400px;
  min-width: 0;
}
.img__hhKYv > picture > img {
  object-fit: contain;
}
.column__v4Wib {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__r5E {
  position: relative;
  object-fit: contain;
  width: 100%;
  flex-shrink: 1;
  max-width: 400px;
  min-width: 0;
}
.img__r5E > picture > img {
  object-fit: contain;
}
.sectors {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: unset;
  background: none;
  width: 100%;
  margin-top: 42px;
  min-width: 0;
  padding: 64px 32px;
}
.text__oyWsx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 1.5em;
  font-weight: 900;
  text-transform: capitalize;
  text-align: center;
  min-width: 0;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__oyWsx {
    text-align: left;
  }
}
.text__kn0Sq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  align-self: center;
  min-width: 0;
}
.foreground {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 960px;
  width: 100%;
  min-width: 0;
}
.foreground > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 48px);
  height: calc(100% + 48px);
}
.foreground > :global(.__wab_flex-container) > *,
.foreground > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.foreground > :global(.__wab_flex-container) > picture > img,
.foreground
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 48px;
}
.freeBox__idHfz {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 960px;
  margin-top: calc(34px + 48px) !important;
}
.freeBox__idHfz > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 48px);
  height: calc(100% + 48px);
}
.freeBox__idHfz > :global(.__wab_flex-container) > *,
.freeBox__idHfz > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__idHfz > :global(.__wab_flex-container) > picture > img,
.freeBox__idHfz
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 48px;
}
.freeBox__vfRAk {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox__vfRAk > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 48px);
  width: calc(100% + 48px);
  margin-top: calc(0px - 40px);
  height: calc(100% + 40px);
}
.freeBox__vfRAk > :global(.__wab_flex-container) > *,
.freeBox__vfRAk > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__vfRAk > :global(.__wab_flex-container) > picture > img,
.freeBox__vfRAk
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 48px;
  margin-top: 40px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__vfRAk {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__vfRAk > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__vfRAk > :global(.__wab_flex-container) > *,
  .freeBox__vfRAk > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__vfRAk > :global(.__wab_flex-container) > picture > img,
  .freeBox__vfRAk
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
.freeBox___7Vsbj {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox___7Vsbj > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox___7Vsbj > :global(.__wab_flex-container) > *,
.freeBox___7Vsbj > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___7Vsbj > :global(.__wab_flex-container) > picture > img,
.freeBox___7Vsbj
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
  margin-top: 8px;
}
.freeBox__qmZfV {
  background: #0000001a;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  border-radius: 8px;
  padding: 8px;
}
.svg__mmj5S {
  object-fit: cover;
  width: 28px;
  height: 28px;
  color: #ffffff;
  flex-shrink: 0;
}
.freeBox__eIS {
  background: #0000001a;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  border-radius: 8px;
  padding: 8px;
}
.svg___4FzF4 {
  object-fit: cover;
  width: 24px;
  height: 24px;
  color: #ffffff;
  flex-shrink: 0;
}
.freeBox___3Gyn1 {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox___3Gyn1 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox___3Gyn1 > :global(.__wab_flex-container) > *,
.freeBox___3Gyn1 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___3Gyn1 > :global(.__wab_flex-container) > picture > img,
.freeBox___3Gyn1
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.text__huDbp {
  color: #ffffffd9;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.text___4N4Lh {
  color: #ffffff80;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  display: none;
}
.freeBox__p21E {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox__p21E > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox__p21E > :global(.__wab_flex-container) > *,
.freeBox__p21E > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__p21E > :global(.__wab_flex-container) > picture > img,
.freeBox__p21E
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
  margin-top: 8px;
}
.freeBox__u2EMl {
  background: #0000001a;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  border-radius: 8px;
  padding: 8px;
}
.svg___61CQn {
  object-fit: cover;
  width: 28px;
  height: 28px;
  color: #ffffff;
  flex-shrink: 0;
}
.freeBox___4IxU {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox___4IxU > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox___4IxU > :global(.__wab_flex-container) > *,
.freeBox___4IxU > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___4IxU > :global(.__wab_flex-container) > picture > img,
.freeBox___4IxU
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.text__nxgz8 {
  color: #ffffffd9;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.freeBox__xrUYx {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox__xrUYx > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox__xrUYx > :global(.__wab_flex-container) > *,
.freeBox__xrUYx > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xrUYx > :global(.__wab_flex-container) > picture > img,
.freeBox__xrUYx
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
  margin-top: 8px;
}
.freeBox__sy0D8 {
  background: #0000001a;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  border-radius: 8px;
  padding: 8px;
}
.svg___46UX {
  object-fit: cover;
  width: 28px;
  height: 28px;
  color: #ffffff;
  flex-shrink: 0;
}
.freeBox__zyaWr {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox__zyaWr > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox__zyaWr > :global(.__wab_flex-container) > *,
.freeBox__zyaWr > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__zyaWr > :global(.__wab_flex-container) > picture > img,
.freeBox__zyaWr
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.text__x02LI {
  color: #ffffffd9;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.text__cAFln {
  color: #ffffff80;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  display: none;
}
.globalRankings {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 1100px;
  margin-top: 70px;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .globalRankings {
    margin-top: 90px;
  }
}
.columns__fH2F {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__fH2F > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 30px);
  width: calc(100% + 30px);
  --plsmc-rc-col-gap: 30px;
}
.columns__fH2F > :global(.__wab_flex-container) > *,
.columns__fH2F > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__fH2F > :global(.__wab_flex-container) > picture > img,
.columns__fH2F
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 30px;
}
.columns__fH2F > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns__fH2F > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__fH2F {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__fH2F > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap-reverse;
    min-width: 0;
    margin-left: calc(0px - 10px);
    width: calc(100% + 10px);
    --plsmc-rc-col-gap: 10px;
    margin-top: calc(0px - 30px);
    height: calc(100% + 30px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__fH2F > :global(.__wab_flex-container) > *,
  .columns__fH2F > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__fH2F > :global(.__wab_flex-container) > picture > img,
  .columns__fH2F
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 10px;
    margin-top: 30px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__fH2F > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__eX36 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  padding: 16px 8px 8px;
}
.reveal__inYcb:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.h2__p6QzN {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 2em;
  font-weight: 900;
  padding-left: 0px;
  line-height: 1.3;
  color: #ffffff;
  min-width: 0;
}
.text___5C5Dc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 1em;
  font-weight: 300;
  padding-bottom: 0px;
  padding-top: 10px;
  min-width: 0;
}
.freeBox___9Dx0T {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  margin-top: 20px;
  min-width: 0;
  padding: 8px 8px 8px 0px;
}
.greenButton:global(.__wab_instance) {
  position: relative;
  align-self: flex-start;
}
.column__joxvw {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__lvwcL {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__lvwcL > picture > img {
  object-fit: cover;
}
.tiles {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 1100px;
  margin-top: 52px;
  min-width: 0;
  padding: 8px;
}
.howItAllWorks {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.howItAllWorks > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 30px);
  width: calc(100% + 30px);
  --plsmc-rc-col-gap: 30px;
}
.howItAllWorks > :global(.__wab_flex-container) > *,
.howItAllWorks > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.howItAllWorks > :global(.__wab_flex-container) > picture > img,
.howItAllWorks
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 30px;
}
.howItAllWorks > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.howItAllWorks > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .howItAllWorks {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .howItAllWorks > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .howItAllWorks > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__k9KCt {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__dImcT {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__dImcT > picture > img {
  object-fit: cover;
}
.column___50Znu {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.reveal__li3LO:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.h2__dzTll {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 1.5em;
  font-weight: 900;
  text-transform: capitalize;
  color: #ffffff;
  min-width: 0;
}
.text__d0BQu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 1.1em;
  font-weight: 300;
  padding-top: 0px;
  margin-top: 10px;
  min-width: 0;
}
.freeBox__u9Lx6 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  margin-top: 20px;
  min-width: 0;
}
.greenButton2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: auto;
  height: auto;
  max-width: 100%;
  background: #136f27;
  align-self: flex-start;
  color: #ffffff;
  border-radius: 4px;
  padding: 6px 14px;
}
.root .greenButton2:hover {
  color: #ffffff;
}
.text__mdAus {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
}
.featuredIn {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  align-self: auto;
  margin-top: 60px;
  max-width: 700px;
  align-content: stretch;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .featuredIn {
    width: 100%;
    min-width: 0;
  }
}
.text__uXF {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  align-self: center;
  min-width: 0;
}
.logosGroup2 {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  width: auto;
  flex-grow: 0;
  align-self: auto;
  flex-shrink: 1;
  margin-top: 5px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .logosGroup2 > :global(.__wab_flex-container) {
    margin-left: calc(0px - 5px);
    width: calc(100% + 5px);
    margin-top: calc(0px - 5px);
    height: calc(100% + 5px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .logosGroup2 > :global(.__wab_flex-container) > *,
  .logosGroup2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .logosGroup2 > :global(.__wab_flex-container) > picture > img,
  .logosGroup2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 5px;
    margin-top: 5px;
  }
}
.columns__q5Ps5 {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  max-width: 900px;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__q5Ps5 > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
}
.columns__q5Ps5 > :global(.__wab_flex-container) > :nth-child(4n + 1) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns__q5Ps5 > :global(.__wab_flex-container) > :nth-child(4n + 2) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns__q5Ps5 > :global(.__wab_flex-container) > :nth-child(4n + 3) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns__q5Ps5 > :global(.__wab_flex-container) > :nth-child(4n + 4) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__q5Ps5 {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__q5Ps5 > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
    margin-left: calc(0px - 10px);
    width: calc(100% + 10px);
    --plsmc-rc-col-gap: 10px;
    margin-top: calc(0px - 10px);
    height: calc(100% + 10px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__q5Ps5 > :global(.__wab_flex-container) > *,
  .columns__q5Ps5 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__q5Ps5 > :global(.__wab_flex-container) > picture > img,
  .columns__q5Ps5
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 10px;
    margin-top: 10px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__q5Ps5 > :global(.__wab_flex-container) > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__q5Ps5 > :global(.__wab_flex-container) > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
.column__jsZ1J {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img___6VwFr {
  max-width: 90px;
  position: relative;
  left: auto;
  top: auto;
}
.column__oGnv6 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__ezi6C {
  max-width: 90px;
  position: relative;
  left: auto;
  top: auto;
}
.column__yz4O9 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__zocI {
  max-width: 90px;
  position: relative;
  left: auto;
  top: auto;
}
.column___8Cucz {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__h8Xwj {
  max-width: 90px;
  position: relative;
  left: auto;
  top: auto;
}
.column___3LRtH {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__ehWk1 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 15px;
}
.img__rSxm {
  max-width: 90px;
  position: relative;
  left: auto;
  top: auto;
}
.column___5J6Xh {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__b9KQt {
  max-width: 90px;
  position: relative;
  left: auto;
  top: auto;
}
.column__ixPqL {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__chaPn {
  max-width: 90px;
  position: relative;
  left: auto;
  top: auto;
}
.column__pkWIe {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__dkYYg {
  max-width: 90px;
  position: relative;
  left: auto;
  top: auto;
}
.contactDiv:global(.__wab_instance) {
  position: relative;
}
.footer:global(.__wab_instance) {
  position: relative;
}
