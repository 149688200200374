.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  background: url("./images/lines3Png46Dab54C328A6A5695AaPng.png") top 50% right 4% / cover repeat, #16161f;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root {
    background: #16161f;
  }
}
.freeBox__pUw1 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 1400px;
  background: none;
  min-width: 0;
  padding: 20px 24px 96px;
}
.navBar2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.hero {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 1400px;
  background: none;
  min-height: 80vh;
  min-width: 0;
  padding: 8px;
}
.tileFeature {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.columns__tAeb {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__tAeb > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 5 / 12);
}
.columns__tAeb > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 7 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__tAeb {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__tAeb > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__mmnLi {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__cvSt7 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.text__eAlg4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 2em;
  font-weight: 900;
  line-height: 1.2;
  min-width: 0;
}
.text__kfJNr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 1.1em;
  font-weight: 300;
  min-width: 0;
}
.freeBox___6HqI6 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  margin-top: 20px;
  min-width: 0;
}
.greenButton {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: auto;
  height: auto;
  max-width: 100%;
  background: var(--token-KG4if5sFq);
  align-self: flex-start;
  color: #ffffff;
  cursor: pointer;
  border-radius: 4px;
  padding: 6px 14px;
}
.root .greenButton:hover {
  color: #ffffff;
}
.text__dnYtF {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
}
.column__epkpm {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img___95DbF {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img___95DbF > picture > img {
  object-fit: cover;
}
.qs {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 1100px;
  background: none;
  min-width: 0;
  padding: 8px;
}
.columns__eexnm {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  max-width: 900px;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__eexnm > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 30px);
  width: calc(100% + 30px);
  --plsmc-rc-col-gap: 30px;
}
.columns__eexnm > :global(.__wab_flex-container) > *,
.columns__eexnm > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__eexnm > :global(.__wab_flex-container) > picture > img,
.columns__eexnm
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 30px;
}
.columns__eexnm > :global(.__wab_flex-container) > :nth-child(3n + 1) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.columns__eexnm > :global(.__wab_flex-container) > :nth-child(3n + 2) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.columns__eexnm > :global(.__wab_flex-container) > :nth-child(3n + 3) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__eexnm {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__eexnm > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__eexnm > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__rDlp6 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.q__k7Djr:global(.__wab_instance) {
  position: relative;
}
.freeBox__oeUxd {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 30px;
  height: 30px;
  max-width: 100%;
  background: none;
  border-radius: 1000px;
  padding: 8px;
  border: 2px solid #ffffff;
}
.text__nBe1Z {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  align-self: center;
  text-align: center;
  line-height: 0.9;
  min-width: 0;
}
.column__h6JbO {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.q__aKd8Z:global(.__wab_instance) {
  position: relative;
}
.freeBox__li6Td {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 30px;
  height: 30px;
  max-width: 100%;
  background: none;
  border-radius: 1000px;
  padding: 8px;
  border: 2px solid #ffffff;
}
.text__an8Px {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  align-self: center;
  text-align: center;
  line-height: 0.9;
  min-width: 0;
}
.column__niRgF {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.q__bkEa3:global(.__wab_instance) {
  position: relative;
}
.freeBox__bWDqz {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 30px;
  height: 30px;
  max-width: 100%;
  background: none;
  border-radius: 1000px;
  padding: 8px;
  border: 2px solid #ffffff;
}
.text__kFfcX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  align-self: center;
  text-align: center;
  line-height: 0.9;
  min-width: 0;
}
.freeBox__ui7Ev {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  margin-top: 30px;
  min-width: 0;
  padding: 8px;
}
.tileFeature2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 1200px;
  background: none;
  min-width: 0;
  padding: 8px;
}
.columns__q7Lww {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__q7Lww > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
}
.columns__q7Lww > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 5 / 12);
}
.columns__q7Lww > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 7 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__q7Lww {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__q7Lww > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap-reverse;
    min-width: 0;
    margin-top: calc(0px - 20px);
    height: calc(100% + 20px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__q7Lww > :global(.__wab_flex-container) > *,
  .columns__q7Lww > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__q7Lww > :global(.__wab_flex-container) > picture > img,
  .columns__q7Lww
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 20px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__q7Lww > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__fa8Zv {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__un93W {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.text__bKnGw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 1.5em;
  font-weight: 900;
  min-width: 0;
}
.text__ew2St {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 1.1em;
  font-weight: 300;
  margin-top: 14px;
  min-width: 0;
}
.freeBox__jQh5 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  margin-top: 20px;
  min-width: 0;
}
.greenButton2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: auto;
  height: auto;
  max-width: 100%;
  background: var(--token-KG4if5sFq);
  align-self: flex-start;
  color: #ffffff;
  cursor: pointer;
  border-radius: 4px;
  padding: 6px 14px;
}
.root .greenButton2:hover {
  color: #ffffff;
}
.text__lqw59 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
}
.column___9EPmj {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__j1EMw {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__j1EMw > picture > img {
  object-fit: cover;
}
.freeBox__woTcp {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 1400px;
  background: #f8faff0a;
  min-width: 0;
  border-radius: 4px;
  padding: 10px 24px;
}
.sectors {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: unset;
  background: none;
  width: 100%;
  margin-top: 0px;
  min-width: 0;
  padding: 15px 32px;
}
.text__wyoMi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 1.5em;
  font-weight: 900;
  text-transform: capitalize;
  text-align: center;
  min-width: 0;
}
.foreground {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.foreground > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 48px);
  height: calc(100% + 48px);
}
.foreground > :global(.__wab_flex-container) > *,
.foreground > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.foreground > :global(.__wab_flex-container) > picture > img,
.foreground
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 48px;
}
.freeBox___5Bfd7 {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 1200px;
  margin-top: calc(34px + 48px) !important;
}
.freeBox___5Bfd7 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 48px);
  height: calc(100% + 48px);
}
.freeBox___5Bfd7 > :global(.__wab_flex-container) > *,
.freeBox___5Bfd7 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___5Bfd7 > :global(.__wab_flex-container) > picture > img,
.freeBox___5Bfd7
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 48px;
}
.columns___0LxwW {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns___0LxwW > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
  --plsmc-rc-col-gap: 10px;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.columns___0LxwW > :global(.__wab_flex-container) > *,
.columns___0LxwW > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns___0LxwW > :global(.__wab_flex-container) > picture > img,
.columns___0LxwW
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
  margin-top: 10px;
}
.columns___0LxwW > :global(.__wab_flex-container) > :nth-child(4n + 1) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns___0LxwW > :global(.__wab_flex-container) > :nth-child(4n + 2) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns___0LxwW > :global(.__wab_flex-container) > :nth-child(4n + 3) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns___0LxwW > :global(.__wab_flex-container) > :nth-child(4n + 4) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___0LxwW {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___0LxwW > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___0LxwW > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__pfT0Z {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.item {
  width: 100%;
  height: auto;
  max-width: 300px;
  min-height: 300px;
  position: relative;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: none;
  min-width: 0;
  border-radius: 25px;
  padding: 16px 8px;
  border: 0.8px solid #ef951e;
}
.freeBox__k4Qwk {
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  border-radius: 8px;
  padding: 8px;
}
.img__zuAyG {
  width: 50px;
  height: 50px;
  max-width: 80px;
  max-height: 80px;
  position: relative;
  object-fit: contain;
  flex-shrink: 0;
}
.img__zuAyG > picture > img {
  object-fit: contain;
}
.freeBox__iGtuq {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox__iGtuq > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox__iGtuq > :global(.__wab_flex-container) > *,
.freeBox__iGtuq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__iGtuq > :global(.__wab_flex-container) > picture > img,
.freeBox__iGtuq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.text__lmk3 {
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: center;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.text__wVX2 {
  color: #ffffff80;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.column__ggZ9 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.item2 {
  width: 100%;
  height: auto;
  max-width: 300px;
  min-height: 300px;
  position: relative;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: none;
  min-width: 0;
  border-radius: 25px;
  padding: 8px 8px 16px;
  border: 0.8px solid #1eef55;
}
.freeBox__b1SGz {
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  border-radius: 8px;
  padding: 8px;
}
.img__gKsX {
  width: 50px;
  height: 50px;
  max-width: 100px;
  max-height: 80px;
  position: relative;
  object-fit: contain;
  flex-shrink: 0;
}
.img__gKsX > picture > img {
  object-fit: contain;
}
.freeBox__oyqWy {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox__oyqWy > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox__oyqWy > :global(.__wab_flex-container) > *,
.freeBox__oyqWy > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__oyqWy > :global(.__wab_flex-container) > picture > img,
.freeBox__oyqWy
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.text__bBy9 {
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: center;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.text__xIOy6 {
  color: #ffffff80;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  padding-right: 0px;
}
.column__huXAl {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.item3 {
  width: 100%;
  height: auto;
  max-width: 300px;
  min-height: 300px;
  position: relative;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: none;
  min-width: 0;
  border-radius: 25px;
  padding: 8px 8px 16px;
  border: 0.8px solid #4ea6ed;
}
.freeBox__mJtB1 {
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  border-radius: 8px;
  padding: 8px;
}
.img__gU2Z2 {
  width: 50px;
  height: 50px;
  max-width: 80px;
  max-height: 80px;
  position: relative;
  object-fit: contain;
  flex-shrink: 0;
}
.img__gU2Z2 > picture > img {
  object-fit: contain;
}
.freeBox__sPyA2 {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox__sPyA2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox__sPyA2 > :global(.__wab_flex-container) > *,
.freeBox__sPyA2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__sPyA2 > :global(.__wab_flex-container) > picture > img,
.freeBox__sPyA2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.text__zkgAp {
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: center;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.text__lqn4M {
  color: #ffffff80;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  padding-right: 0px;
}
.column__urP5B {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.item4 {
  width: 100%;
  height: auto;
  max-width: 300px;
  min-height: 300px;
  position: relative;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: none;
  min-width: 0;
  border-radius: 25px;
  padding: 8px 8px 16px;
  border: 0.8px solid #aa5eff;
}
.freeBox__pEmF4 {
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  border-radius: 8px;
  padding: 8px;
}
.img__rCvEg {
  width: 50px;
  height: 50px;
  max-width: 100px;
  max-height: 80px;
  position: relative;
  object-fit: contain;
  flex-shrink: 0;
}
.img__rCvEg > picture > img {
  object-fit: contain;
}
.freeBox__asPr8 {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox__asPr8 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox__asPr8 > :global(.__wab_flex-container) > *,
.freeBox__asPr8 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__asPr8 > :global(.__wab_flex-container) > picture > img,
.freeBox__asPr8
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.text__fuF0R {
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: center;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.text___3EFmg {
  color: #ffffff80;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.freeBox__hAoEm {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 1400px;
  background: none;
  min-width: 0;
  padding: 8px;
}
.freeBox___82S8R {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 1400px;
  background: none;
  min-width: 0;
  padding: 20px 24px;
}
.freeBox__aCciA {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  margin-top: 68px;
  min-width: 0;
  padding: 8px;
}
.text___4Jqt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 1.5em;
  font-weight: 900;
  text-align: center;
  min-width: 0;
}
.qs2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.columns__kaWxt {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  max-width: 900px;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__kaWxt > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 30px);
  width: calc(100% + 30px);
  --plsmc-rc-col-gap: 30px;
}
.columns__kaWxt > :global(.__wab_flex-container) > *,
.columns__kaWxt > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__kaWxt > :global(.__wab_flex-container) > picture > img,
.columns__kaWxt
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 30px;
}
.columns__kaWxt > :global(.__wab_flex-container) > :nth-child(3n + 1) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.columns__kaWxt > :global(.__wab_flex-container) > :nth-child(3n + 2) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.columns__kaWxt > :global(.__wab_flex-container) > :nth-child(3n + 3) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__kaWxt {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__kaWxt > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__kaWxt > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__nhGdk {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__yHl5E {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  align-self: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__yHl5E {
    justify-content: center;
    align-items: center;
  }
}
.img__kSoI {
  position: relative;
  object-fit: contain;
  max-width: 100%;
  width: 50px;
  height: 50px;
  align-self: center;
  flex-shrink: 0;
}
.img__kSoI > picture > img {
  object-fit: contain;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__kSoI {
    align-self: center;
  }
}
.text__slBYe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 1.5em;
  font-weight: 900;
  margin-top: 24px;
  text-align: center;
  min-height: 80px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__slBYe {
    align-self: center;
    text-align: center;
    min-height: auto;
    line-height: 1.2;
    font-weight: 300;
  }
}
.text___55AkE {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 0.8em;
  font-weight: 400;
  min-width: 0;
  display: none;
}
.ul__stxE {
  display: flex;
  flex-direction: column;
  padding: 0px;
}
.li__yPr7Q {
  margin-left: 10px;
  margin-top: 10px;
}
.li__ovhAl {
  margin-left: 10px;
}
.column___3OXeg {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__jWjY {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.img__fgeKd {
  position: relative;
  object-fit: contain;
  max-width: 100%;
  width: 50px;
  height: 50px;
  align-self: center;
  flex-shrink: 0;
}
.img__fgeKd > picture > img {
  object-fit: contain;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__fgeKd {
    align-self: center;
  }
}
.text__cmHuA {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 1.5em;
  font-weight: 900;
  margin-top: 24px;
  text-align: center;
  min-height: 80px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__cmHuA {
    text-align: center;
    min-height: auto;
    line-height: 1.2;
    font-weight: 300;
  }
}
.text__tSsIo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 0.8em;
  font-weight: 400;
  min-width: 0;
  display: none;
}
.ul__bkhGn {
  display: flex;
  flex-direction: column;
  padding: 0px;
}
.li__i6SL4 {
  margin-left: 10px;
  margin-top: 10px;
}
.li__jp4Pb {
  margin-left: 10px;
}
.column___7O0H6 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__iPdMb {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.img__k2Qss {
  position: relative;
  object-fit: contain;
  max-width: 100%;
  width: 50px;
  height: 50px;
  align-self: center;
  flex-shrink: 0;
}
.img__k2Qss > picture > img {
  object-fit: contain;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__k2Qss {
    align-self: center;
  }
}
.text__hgt5M {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 1.5em;
  font-weight: 900;
  margin-top: 24px;
  text-align: center;
  min-height: 80px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__hgt5M {
    align-self: auto;
    text-align: center;
    min-height: auto;
    line-height: 1.2;
    font-weight: 300;
  }
}
.text___39Yf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 0.8em;
  font-weight: 400;
  min-width: 0;
  display: none;
}
.ul___9Rhxk {
  display: flex;
  flex-direction: column;
  padding: 0px;
}
.li__zutll {
  margin-left: 10px;
  margin-top: 10px;
}
.li__beQu {
  margin-left: 10px;
}
.freeBox__ehnOd {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.tileFeature4 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.columns__gzqtK {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__gzqtK > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 7 / 12);
}
.columns__gzqtK > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 5 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__gzqtK {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__gzqtK > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__csKEg {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__mImQ6 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__mImQ6 > picture > img {
  object-fit: cover;
}
.column__tHfPj {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox___7HDbS {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.text__g38R {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 1.5em;
  font-weight: 900;
  padding-bottom: 0px;
  min-width: 0;
}
.text__dxD1J {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-weight: 300;
  padding-top: 0px;
  margin-top: 12px;
  min-width: 0;
}
.freeBox__h5DpW {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  margin-top: 20px;
  min-width: 0;
}
.greenButton5 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: auto;
  height: auto;
  max-width: 100%;
  background: var(--token-KG4if5sFq);
  align-self: flex-start;
  color: #ffffff;
  cursor: pointer;
  border-radius: 4px;
  padding: 6px 14px;
}
.root .greenButton5:hover {
  color: #ffffff;
}
.text__quThF {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
}
.freeBox__xf1FG {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 64px;
}
.freeBox__xf1FG > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 64px);
  height: calc(100% + 64px);
}
.freeBox__xf1FG > :global(.__wab_flex-container) > *,
.freeBox__xf1FG > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xf1FG > :global(.__wab_flex-container) > picture > img,
.freeBox__xf1FG
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 64px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__xf1FG {
    width: 100%;
    min-width: 0;
    padding: 128px 32px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__xf1FG > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.freeBox__hm5Fw {
  display: flex;
  position: relative;
  flex-direction: column;
  top: auto;
  left: auto;
  max-width: 1080px;
}
.freeBox__hm5Fw > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 64px);
  height: calc(100% + 64px);
}
.freeBox__hm5Fw > :global(.__wab_flex-container) > *,
.freeBox__hm5Fw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__hm5Fw > :global(.__wab_flex-container) > picture > img,
.freeBox__hm5Fw
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 64px;
}
.freeBox__rF6I {
  display: flex;
  flex-direction: column;
}
.freeBox__rF6I > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__rF6I > :global(.__wab_flex-container) > *,
.freeBox__rF6I > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__rF6I > :global(.__wab_flex-container) > picture > img,
.freeBox__rF6I
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.freeBox__eWbGg {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.text__bsRwU {
  font-size: 1.5em;
  font-weight: 900;
  color: #fff6f6;
  line-height: 1;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__bsRwU {
    text-align: center;
  }
}
.text__zqxrD {
  font-size: 1em;
  color: #ffffff;
  text-align: center;
  line-height: 28px;
  height: auto;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  max-width: 800px;
  display: none;
}
.freeBox__lfQis {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox__lfQis > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__lfQis > :global(.__wab_flex-container) > *,
.freeBox__lfQis > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lfQis > :global(.__wab_flex-container) > picture > img,
.freeBox__lfQis
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__lfQis {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__lfQis > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__lfQis > :global(.__wab_flex-container) > *,
  .freeBox__lfQis > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__lfQis > :global(.__wab_flex-container) > picture > img,
  .freeBox__lfQis
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox__xLxeD {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  background: none;
  min-width: 0;
  border-radius: 16px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__xLxeD {
    background: #36363666;
    border: 0.5px solid #bfbfbf;
  }
}
.freeBox__xJn5O {
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 450px;
  padding: 32px;
}
.freeBox__xJn5O > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.freeBox__xJn5O > :global(.__wab_flex-container) > *,
.freeBox__xJn5O > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xJn5O > :global(.__wab_flex-container) > picture > img,
.freeBox__xJn5O
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.img__ap71P {
  position: relative;
  object-fit: cover;
  max-width: 200px;
  width: 30%;
  align-self: center;
}
.img__ap71P > picture > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__ap71P {
    width: 50%;
  }
}
.text__qn131 {
  font-size: 2.5em;
  font-weight: 800;
  line-height: 1;
  color: #ffffffd9;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: center;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  padding-right: 0px;
  padding-bottom: 0px;
  min-height: 110px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__qn131 {
    text-align: center;
    min-height: auto;
  }
}
.freeBox__wu4K2 {
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 220px;
}
.freeBox__wu4K2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox__wu4K2 > :global(.__wab_flex-container) > *,
.freeBox__wu4K2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__wu4K2 > :global(.__wab_flex-container) > picture > img,
.freeBox__wu4K2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.text__gtGw0 {
  font-size: 20px;
  font-weight: 800;
  line-height: 28px;
  color: #ffffffd9;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: center;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__gtGw0 {
    text-align: center;
  }
}
.ul___0MWkk {
  display: flex;
  flex-direction: column;
}
.ul___0MWkk > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: calc(0px - 15px);
  height: calc(100% + 15px);
}
.ul___0MWkk > :global(.__wab_flex-container) > *,
.ul___0MWkk > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.ul___0MWkk > :global(.__wab_flex-container) > picture > img,
.ul___0MWkk
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 15px;
}
.li__l8PLh {
  text-align: left;
  font-weight: 300;
  font-size: 0.9em;
}
.li___8B2Sp {
  text-align: left;
  font-weight: 300;
  font-size: 0.9em;
}
.text__ndsGu {
  color: #ffffff99;
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  display: none;
}
.link__evIr4 {
  background: var(--token-KG4if5sFq);
  position: relative;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  -webkit-transition-property: all;
  -webkit-transition-timing-function: ease-out;
  -webkit-transition-duration: 0.5s;
  border-radius: 6px;
  padding: 10px 16px;
}
.link__evIr4 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.link__evIr4 > :global(.__wab_flex-container) > *,
.link__evIr4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.link__evIr4 > :global(.__wab_flex-container) > picture > img,
.link__evIr4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.svg___7M2Av {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
.text__vrchW {
  color: #ffffffd9;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.svg___50O6W {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
.line {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 1px;
  background: #ffffff66;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .line {
    display: none;
  }
}
.freeBox___8TM62 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  background: none;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___8TM62 {
    background: #36363666;
    border-radius: 16px;
    border: 0.5px solid #bfbfbf;
  }
}
.freeBox___935Pj {
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 450px;
  padding: 32px;
}
.freeBox___935Pj > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.freeBox___935Pj > :global(.__wab_flex-container) > *,
.freeBox___935Pj > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___935Pj > :global(.__wab_flex-container) > picture > img,
.freeBox___935Pj
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.img__yWmUt {
  position: relative;
  object-fit: cover;
  max-width: 200px;
  width: 30%;
  align-self: center;
}
.img__yWmUt > picture > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__yWmUt {
    width: 50%;
  }
}
.text__yeoDt {
  font-size: 2.5em;
  font-weight: 800;
  line-height: 1.2;
  color: #ffffffd9;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: center;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-height: 110px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__yeoDt {
    text-align: center;
  }
}
.freeBox__jwdj0 {
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 220px;
}
.freeBox__jwdj0 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox__jwdj0 > :global(.__wab_flex-container) > *,
.freeBox__jwdj0 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__jwdj0 > :global(.__wab_flex-container) > picture > img,
.freeBox__jwdj0
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.text___8An6T {
  color: #ffffff99;
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  display: none;
}
.text___6PG7 {
  font-size: 20px;
  font-weight: 800;
  line-height: 28px;
  color: #ffffffd9;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: center;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text___6PG7 {
    text-align: center;
  }
}
.ul__k5Kcm {
  display: flex;
  flex-direction: column;
}
.ul__k5Kcm > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: calc(0px - 15px);
  height: calc(100% + 15px);
}
.ul__k5Kcm > :global(.__wab_flex-container) > *,
.ul__k5Kcm > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.ul__k5Kcm > :global(.__wab_flex-container) > picture > img,
.ul__k5Kcm
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 15px;
}
.li__kHvbQ {
  text-align: left;
  font-weight: 300;
  font-size: 0.9em;
}
.li__l8Kv0 {
  text-align: left;
  font-weight: 300;
  font-size: 0.9em;
}
.link__bbvvD {
  background: var(--token-KG4if5sFq);
  position: relative;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  -webkit-transition-property: all;
  -webkit-transition-timing-function: ease-out;
  -webkit-transition-duration: 0.5s;
  border-radius: 6px;
  padding: 10px 16px;
}
.link__bbvvD > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.link__bbvvD > :global(.__wab_flex-container) > *,
.link__bbvvD > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.link__bbvvD > :global(.__wab_flex-container) > picture > img,
.link__bbvvD
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.svg___6Me0N {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
.text__ldWdG {
  height: auto;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.svg__drmv {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
.freeBox__cd7CO {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 1400px;
  background: none;
  min-width: 0;
  padding: 8px;
}
.freeBox__w8SUg {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 1400px;
  background: none;
  min-width: 0;
}
.freeBox__vEUyD {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 20px 24px 96px;
}
.contactDiv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.footer:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
