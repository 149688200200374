.greenButton {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: auto;
  height: auto;
  max-width: 100%;
  background: linear-gradient(110deg, var(--token-KG4if5sFq) 34%, #136f27 100%);
  color: #ffffff;
  cursor: pointer;
  position: relative;
  transition-property: all;
  transition-duration: 0.3s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  border-radius: 4px;
  padding: 6px 14px;
}
.greenButton:hover {
  color: #ffffff;
  background: linear-gradient(110deg, var(--token-KG4if5sFq) 34%, #118b2b 100%);
}
.text {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
}
