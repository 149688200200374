.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  cursor: pointer;
  min-width: 0;
  border-width: 0px;
}
.link {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
.freeBox__dvgf2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 0px;
}
.brandName {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  font-size: 1.2em;
  color: #ffffff;
  min-width: 0;
}
.slotTargetBrandName {
  font-size: 1em;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 0px;
}
.freeBox__lwrEd {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100px;
  background: none;
  min-width: 0;
  padding: 0px 8px;
}
.freeBox__b5OBh {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  align-self: flex-end;
  min-width: 0;
  padding: 0px;
}
.vertical {
  position: relative;
  width: auto;
  height: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  min-height: 0;
}
.slotTargetBrandAggScore {
  font-size: 1.6em;
  font-weight: 900;
  color: #1affff;
  letter-spacing: normal;
}
.hundred {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #c2c2c2;
  font-weight: 400;
  font-size: 1em;
  letter-spacing: normal;
  align-self: flex-end;
}
