.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 1200px;
  background: none;
  position: relative;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root {
    background: #00000033;
  }
}
.freeBox__asFmc {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: url("./images/callToActionBackgroundPng771Afa1340297Df002FdPng.png") left 50% top 6.25% / cover no-repeat
    padding-box border-box;
  min-height: 300px;
  min-width: 0;
  border-radius: 35px;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__asFmc {
    background: linear-gradient(#00000066, #00000066),
      url("./images/callToActionBackgroundPng771Afa1340297Df002FdPng.png") left 100% bottom 100% / cover no-repeat
        padding-box border-box;
    align-self: auto;
    height: auto;
  }
}
.freeBox___2R8Lz {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 15px;
}
.columns {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  align-self: auto;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  margin-top: calc(0px - 15px);
  height: calc(100% + 15px);
}
.columns > :global(.__wab_flex-container) > *,
.columns > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns > :global(.__wab_flex-container) > picture > img,
.columns
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 15px;
}
.columns > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__onZza {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox___6O9A {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.text__ueWyq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #535353;
  font-weight: 900;
  font-size: 2em;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__ueWyq {
    color: #ffffff;
  }
}
.freeBox__vNtsE {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.text__lHeQw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 1.1em;
  color: #272727;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__lHeQw {
    color: #ffffff;
  }
}
.column__tzc4N {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__dnRh7 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.freeBox__yKyPe {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  align-self: auto;
  min-width: 0;
  border-radius: 25px;
  padding: 8px;
}
.freeBox__yKyPe > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__yKyPe > :global(.__wab_flex-container) {
    flex-wrap: wrap;
    align-items: stretch;
    margin-top: calc(0px - 10px);
    height: calc(100% + 10px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__yKyPe > :global(.__wab_flex-container) > *,
  .freeBox__yKyPe > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__yKyPe > :global(.__wab_flex-container) > picture > img,
  .freeBox__yKyPe
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 10px;
  }
}
.freeBox__mbyR {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
}
.textInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox___6Gnn {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
}
.subscribeButton:global(.__wab_instance) {
  position: relative;
}
