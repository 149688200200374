.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 320px;
  background: none;
  position: relative;
  min-height: 200px;
  min-width: 280px;
  max-height: 320px;
  cursor: pointer;
  padding: 8px;
  border-width: 0px;
}
.rootleftAligned {
  padding-left: 0px;
}
.rootnobox {
  border-style: none;
}
.cityLink {
  position: relative;
  display: flex;
  flex-direction: row;
  color: #363636;
}
.cityLinkleftAligned {
  text-align: left;
}
.freeBox__fEIdX {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.freeBox___2Hv6W {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.freeBox__hvkXe {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  align-self: center;
  max-height: 350px;
  min-width: 0;
  padding: 8px;
}
.freeBox__hvkXe > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  align-content: stretch;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__hvkXe > :global(.__wab_flex-container) > *,
.freeBox__hvkXe > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__hvkXe > :global(.__wab_flex-container) > picture > img,
.freeBox__hvkXe
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.freeBoxnotWrapped__hvkXekenmz > :global(.__wab_flex-container) {
  flex-wrap: nowrap;
  align-content: stretch;
}
.freeBoxleftAligned__hvkXErUgth {
  padding-left: 0px;
}
.mapImg {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 120px;
  background: none;
  align-self: center;
  border-radius: 10000px;
  padding: 0px;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100px;
  max-height: 100px;
  width: 80px;
  height: 80px;
  align-self: center;
  flex-shrink: 0;
  border-radius: 500px;
  border: 0.2px solid #0fd81799;
}
.img > picture > img {
  object-fit: cover;
}
.freeBox__jSk8K {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.freeBoxleftAligned__jSk8KrUgth {
  justify-content: center;
  align-items: flex-start;
  padding-left: 0px;
}
.cityH1 {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  font-size: 1em;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
  margin-bottom: 3px;
  min-width: 0;
}
.slotTargetCityH1 {
  font-size: 1.5em;
  font-weight: 600;
  letter-spacing: -1px;
  color: #0fd817;
}
.slotTargetCityH1notWrapped {
  color: #0fd817;
}
.numBrands {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetNumBrands {
  color: #ffffff;
  font-size: 1em;
  font-weight: 400;
}
.numLocations {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetNumLocations {
  color: #ffffff;
  font-size: 1em;
  font-weight: 400;
}
.greenButton3 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  background: #136f27;
  align-self: flex-start;
  margin-top: 7px;
  border-radius: 3px;
  padding: 4px 10px;
  border-width: 0px;
}
.greenButton3showButton {
  display: none;
}
.text {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 1em;
  color: #ffffff;
}
