.root {
  object-fit: cover;
  max-width: 100%;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  border-radius: 10px;
}
.root > picture > img {
  object-fit: cover;
}
