.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  position: relative;
  min-width: 0;
  padding: 8px;
}
.rootleftAligned {
  padding-left: 0px;
}
.cityLink {
  position: relative;
  display: flex;
  flex-direction: row;
  color: #363636;
}
.cityLinkleftAligned {
  text-align: left;
}
.freeBox__vZhYg {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.freeBoxnotWrapped__vZhYgkenmz {
  height: auto;
  display: flex;
  flex-direction: column;
  flex-column-gap: 0px;
}
.freeBoxleftAligned__vZhYGrUgth {
  justify-content: center;
  align-items: flex-start;
  padding-left: 8px;
}
.freeBox__hvkXe {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  flex-wrap: wrap;
  align-self: auto;
  min-width: 0;
  padding: 8px;
}
.freeBoxnotWrapped__hvkXekenmz {
  flex-wrap: nowrap;
  align-content: stretch;
}
.freeBoxleftAligned__hvkXErUgth {
  padding-left: 0px;
}
.freeBox__jSk8K {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.freeBoxleftAligned__jSk8KrUgth {
  justify-content: center;
  align-items: flex-start;
  padding-left: 0px;
}
.cityH1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  font-size: 1em;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
  min-width: 0;
}
.slotTargetCityH1 {
  font-size: 2em;
  font-weight: 600;
  letter-spacing: -1px;
  color: #16b81d;
}
.numBrands {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.numLocations {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.freeBox___3D7Ah {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___3D7Ah {
    display: none;
  }
}
.cityLocCountCircleChart:global(.__wab_instance) {
  position: relative;
}
.img__xpNJg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  align-self: auto;
}
.img__xpNJg > picture > img {
  object-fit: cover;
}
.img__xpNJg > .__wab_img-spacer > img {
  object-fit: cover;
}
