.root {
  display: flex;
  position: relative;
}
.img__cCEnP {
  object-fit: cover;
  max-width: 100%;
  position: relative;
}
.img__cCEnP > picture > img {
  object-fit: cover;
}
