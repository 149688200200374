.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  position: relative;
  min-width: 0;
  padding: 8px;
}
