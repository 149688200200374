.root {
  display: flex;
  flex-direction: row;
  position: relative;
  min-width: 200px;
  width: 100%;
  cursor: pointer;
  padding: 20px 10px;
  border-width: 0px;
}
.rootfullWidthAcross {
  width: 100%;
  min-width: 0;
}
.link {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
}
.freeBox___9Fh8S {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.freeBox__pv8Qd {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.freeBox__nOzSr {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 0px;
}
.accountName {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
.slotTargetAccountName {
  font-size: 0.9em;
  color: #1dffff;
}
.tweetText {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
  align-self: flex-start;
  min-width: 0;
}
.slotTargetTweetText {
  font-size: 1em;
  font-weight: 400;
  color: #ffffff;
}
.freeBox__oxTc6 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: auto;
  height: auto;
  max-width: 100%;
  background: none;
  align-self: center;
  min-width: 120px;
  padding: 8px;
}
.greenButton3 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  background: var(--token-KG4if5sFq);
  align-self: flex-end;
  border-radius: 7px;
  padding: 6px 14px;
  border-width: 0px;
}
.text {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 1em;
  color: #ffffff;
  white-space: pre;
}
