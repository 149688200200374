.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #2a9e1c;
  position: relative;
  cursor: pointer;
  min-width: 0;
  border-radius: 20px;
  padding: 8px;
}
.rootsubscribed {
  background: #20d40a;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  color: #ffffff;
  font-weight: 900;
  font-size: 1.4em;
  min-width: 0;
}
