.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-wrap: wrap;
  background: #26262f;
  position: relative;
  min-width: 0;
  border-radius: 5px;
  padding: 15px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root {
    padding: 0px;
  }
}
.roottransparentBackground {
  background: #f2f2f2ad;
}
.freeBox__svvk {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.reveal:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.freeBox__c8A0W {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.freeBox__wxKk5 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.text__gkmrt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-size: 2em;
  font-weight: 900;
  line-height: 1;
  min-width: 0;
}
.texttransparentBackground__gkmrta7Rdh {
  color: #16161f;
  padding-left: 9px;
}
.text__c6Rc2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-weight: 700;
  margin-top: 10px;
  padding-bottom: 0px;
  min-width: 0;
}
.texttransparentBackground__c6Rc2A7Rdh {
  color: #16161f;
  display: none;
}
.freeBox__crCjZ {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.columns__ggz6 {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 2px 8px;
}
.columns__ggz6 > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns__ggz6 > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__ggz6 {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__ggz6 > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__k82SB {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.fname {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #969696;
  font-size: 0.94em;
  font-style: italic;
  font-weight: 300;
  background: none;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: solid;
  border-left-style: none;
  border-bottom-color: #474747c4;
  border-top-color: #47474700;
  border-right-color: #47474700;
  border-left-color: #47474700;
  min-width: 0;
  padding: 2px 2px 2px 0px;
  border-width: 1px;
}
.fnametransparentBackground {
  color: #16161f;
}
.column__cTHi {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.lname {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #969696;
  font-size: 0.94em;
  font-style: italic;
  font-weight: 300;
  background: none;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: solid;
  border-left-style: none;
  border-bottom-color: #474747c4;
  border-top-color: #47474700;
  border-right-color: #47474700;
  border-left-color: #47474700;
  min-width: 0;
  padding: 2px 2px 2px 0px;
  border-width: 1px;
}
.lnametransparentBackground {
  color: #16161f;
}
.columns__wvcVn {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 2px 8px;
}
.columns__wvcVn > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__wvcVn {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__wvcVn > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__ubfkE {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.wemail {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #969696;
  font-size: 0.94em;
  font-style: italic;
  font-weight: 300;
  background: none;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: solid;
  border-left-style: none;
  border-bottom-color: #474747c4;
  border-top-color: #47474700;
  border-right-color: #47474700;
  border-left-color: #47474700;
  min-width: 0;
  padding: 2px 2px 2px 0px;
  border-width: 1px;
}
.wemailtransparentBackground {
  color: #16161f;
}
.columns__dqTGd {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 2px 8px;
}
.columns__dqTGd > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__dqTGd {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__dqTGd > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__u7O8 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.cityresidence {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #969696;
  font-size: 0.94em;
  font-style: italic;
  font-weight: 300;
  background: none;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: solid;
  border-left-style: none;
  border-bottom-color: #474747c4;
  border-top-color: #47474700;
  border-right-color: #47474700;
  border-left-color: #47474700;
  min-width: 0;
  padding: 2px 2px 2px 0px;
  border-width: 1px;
}
.cityresidencetransparentBackground {
  color: #16161f;
}
.columns__e6E6O {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 2px 8px;
}
.columns__e6E6O > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__e6E6O {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__e6E6O > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__gPlWm {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.industry {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #969696;
  font-size: 0.94em;
  font-style: italic;
  font-weight: 300;
  background: none;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: solid;
  border-left-style: none;
  border-bottom-color: #474747c4;
  border-top-color: #47474700;
  border-right-color: #47474700;
  border-left-color: #47474700;
  min-width: 0;
  padding: 2px 2px 2px 0px;
  border-width: 1px;
}
.industrytransparentBackground {
  color: #16161f;
}
.freeBox__iWywe {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  margin-top: 12px;
  min-width: 0;
  padding: 8px;
}
.formEnterButton:global(.__wab_instance) {
  position: relative;
  align-self: flex-start;
}
