.root {
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-weight: 800;
  font-size: 0.95em;
  position: relative;
  cursor: pointer;
  min-width: 0;
  border-width: 0px;
}
