.root {
  display: flex;
  position: relative;
}
.img__zQ2Ul {
  object-fit: cover;
  max-width: 100%;
  position: relative;
}
.img__zQ2Ul > picture > img {
  object-fit: cover;
}
