.root {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox__bQlP {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 20px;
}
.freeBox__zqVm2 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-self: center;
  max-width: 1100px;
  min-width: 0;
  min-height: 0;
}
.freeBox__zqVm2 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: space-around;
  min-width: 0;
  min-height: 0;
  margin-left: calc(0px - 30px);
  width: calc(100% + 30px);
}
.freeBox__zqVm2 > :global(.__wab_flex-container) > *,
.freeBox__zqVm2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__zqVm2 > :global(.__wab_flex-container) > picture > img,
.freeBox__zqVm2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 30px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__zqVm2 {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__zqVm2 > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 64px);
    height: calc(100% + 64px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__zqVm2 > :global(.__wab_flex-container) > *,
  .freeBox__zqVm2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__zqVm2 > :global(.__wab_flex-container) > picture > img,
  .freeBox__zqVm2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 64px;
  }
}
.freeBox__agoCf {
  display: flex;
  position: relative;
  flex-direction: column;
  top: auto;
  left: auto;
  width: 100%;
  max-width: 600px;
  min-width: 0;
}
.freeBox__agoCf > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 15px);
  height: calc(100% + 15px);
}
.freeBox__agoCf > :global(.__wab_flex-container) > *,
.freeBox__agoCf > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__agoCf > :global(.__wab_flex-container) > picture > img,
.freeBox__agoCf
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 15px;
}
.link__eSnJw {
  position: relative;
  display: flex;
}
.img {
  opacity: 1;
  object-fit: contain;
  width: 180px;
  position: relative;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: contain;
}
.freeBox__d4NP {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox__d4NP > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__d4NP > :global(.__wab_flex-container) > *,
.freeBox__d4NP > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__d4NP > :global(.__wab_flex-container) > picture > img,
.freeBox__d4NP
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.text__itlWa {
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  font-weight: 300;
  font-style: normal;
  color: #ffffff;
  text-align: left;
  text-transform: none;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.freeBox__pcjCt {
  display: flex;
  position: relative;
  flex-direction: row;
  margin-top: calc(10px + 16px) !important;
}
.freeBox__pcjCt > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__pcjCt > :global(.__wab_flex-container) > *,
.freeBox__pcjCt > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__pcjCt > :global(.__wab_flex-container) > picture > img,
.freeBox__pcjCt
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.link__r69Hc {
  position: relative;
  display: flex;
}
.svg__wgVn0 {
  object-fit: cover;
  width: 26px;
  height: 100%;
  color: #ffffff;
  flex-shrink: 0;
  min-height: 0;
}
.link__gZ6E7 {
  position: relative;
  display: flex;
}
.svg__dsomf {
  object-fit: cover;
  width: 26px;
  height: 100%;
  color: #ffffff;
  flex-shrink: 0;
  min-height: 0;
}
.freeBox___1IP5X {
  display: flex;
  flex-direction: row;
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  padding: 0px;
}
.freeBox___1IP5X > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 70px);
  width: calc(100% + 70px);
}
.freeBox___1IP5X > :global(.__wab_flex-container) > *,
.freeBox___1IP5X > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___1IP5X > :global(.__wab_flex-container) > picture > img,
.freeBox___1IP5X
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 70px;
}
.freeBox__wwKlc {
  display: flex;
  width: auto;
  flex-direction: column;
  padding-bottom: 0px;
}
.freeBox__wwKlc > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__wwKlc > :global(.__wab_flex-container) > *,
.freeBox__wwKlc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__wwKlc > :global(.__wab_flex-container) > picture > img,
.freeBox__wwKlc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.text__iuGkr {
  position: relative;
  left: auto;
  top: auto;
  color: #898989;
  height: auto;
  font-size: 1.1em;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5;
  letter-spacing: 0.03em;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.link__kuNhD {
  position: relative;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  -webkit-transition-property: all;
  -webkit-transition-timing-function: ease-out;
  -webkit-transition-duration: 0.5s;
  border-radius: 6px;
  padding: 0px;
  border: 1px solid transparent;
}
.link__kuNhD > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.link__kuNhD > :global(.__wab_flex-container) > *,
.link__kuNhD > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.link__kuNhD > :global(.__wab_flex-container) > picture > img,
.link__kuNhD
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.svg__oSa0 {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
.text__k7Nux {
  font-family: "Inter", sans-serif;
  font-size: 1em;
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
  text-align: left;
  text-transform: none;
  line-height: 26px;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  height: auto;
}
.svg__oGmsM {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
.link__actc9 {
  position: relative;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  -webkit-transition-property: all;
  -webkit-transition-timing-function: ease-out;
  -webkit-transition-duration: 0.5s;
  border-radius: 6px;
  padding: 0px;
  border: 1px solid transparent;
}
.link__actc9 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.link__actc9 > :global(.__wab_flex-container) > *,
.link__actc9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.link__actc9 > :global(.__wab_flex-container) > picture > img,
.link__actc9
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.svg__tSipe {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
.text__f1G2N {
  font-family: "Inter", sans-serif;
  font-size: 1em;
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
  text-align: left;
  text-transform: none;
  line-height: 26px;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  height: auto;
}
.svg__dnmjr {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
.link__xRbW {
  position: relative;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  -webkit-transition-property: all;
  -webkit-transition-timing-function: ease-out;
  -webkit-transition-duration: 0.5s;
  border-radius: 6px;
  padding: 0px;
  border: 1px solid transparent;
}
.link__xRbW > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.link__xRbW > :global(.__wab_flex-container) > *,
.link__xRbW > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.link__xRbW > :global(.__wab_flex-container) > picture > img,
.link__xRbW
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.svg__wu86T {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
.text__rapOx {
  font-family: "Inter", sans-serif;
  font-size: 1em;
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
  text-align: left;
  text-transform: none;
  line-height: 26px;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.svg__xJ5M7 {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
.link__svGnn {
  position: relative;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  -webkit-transition-property: all;
  -webkit-transition-timing-function: ease-out;
  -webkit-transition-duration: 0.5s;
  border-radius: 6px;
  padding: 0px;
  border: 1px solid transparent;
}
.link__svGnn > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.link__svGnn > :global(.__wab_flex-container) > *,
.link__svGnn > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.link__svGnn > :global(.__wab_flex-container) > picture > img,
.link__svGnn
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.svg__vdejR {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
.text__o2Gr4 {
  font-family: "Inter", sans-serif;
  font-size: 1em;
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
  text-align: left;
  text-transform: none;
  line-height: 26px;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  height: auto;
}
.svg__jlvvj {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
.freeBox___00L {
  display: flex;
  position: relative;
  width: auto;
  left: auto;
  top: auto;
  flex-direction: column;
}
.freeBox___00L > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox___00L > :global(.__wab_flex-container) > *,
.freeBox___00L > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___00L > :global(.__wab_flex-container) > picture > img,
.freeBox___00L
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.text___3JFwf {
  position: relative;
  left: auto;
  top: auto;
  color: #898989;
  height: auto;
  font-size: 1.1em;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5;
  letter-spacing: 0.03em;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.link__mtcOo {
  position: relative;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  -webkit-transition-property: all;
  -webkit-transition-timing-function: ease-out;
  -webkit-transition-duration: 0.5s;
  border-radius: 6px;
  padding: 0px;
  border: 1px solid transparent;
}
.link__mtcOo > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.link__mtcOo > :global(.__wab_flex-container) > *,
.link__mtcOo > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.link__mtcOo > :global(.__wab_flex-container) > picture > img,
.link__mtcOo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.svg__vlxqt {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
.text___4DnCc {
  font-family: "Inter", sans-serif;
  font-size: 1em;
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
  text-align: left;
  text-transform: none;
  line-height: 26px;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.svg__qj0Oh {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
.link__vwOEt {
  position: relative;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  -webkit-transition-property: all;
  -webkit-transition-timing-function: ease-out;
  -webkit-transition-duration: 0.5s;
  border-radius: 6px;
  padding: 0px;
  border: 1px solid transparent;
}
.link__vwOEt > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.link__vwOEt > :global(.__wab_flex-container) > *,
.link__vwOEt > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.link__vwOEt > :global(.__wab_flex-container) > picture > img,
.link__vwOEt
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.svg__lma3F {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
.text__tm9Aa {
  font-family: "Inter", sans-serif;
  font-size: 1em;
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
  text-align: left;
  text-transform: none;
  line-height: 26px;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.svg__bz58B {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
