.root {
  display: flex;
  position: relative;
}
.img {
  position: relative;
  object-fit: contain;
  max-width: 100%;
}
.img > picture > img {
  object-fit: contain;
}
