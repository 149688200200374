.root {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: url("./images/lines3Png.png") top 50% right 0% / contain no-repeat,
    #161620;
  min-width: 0;
  min-height: 0;
  padding: 20px 24px 96px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 16px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root {
    background: url("./images/lines3Png.png") top 50% right -40px / cover no-repeat,
      #161620;
  }
}
.navBar2:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .navBar2:global(.__wab_instance) {
    position: relative;
  }
}
.freeBox___1IQct {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  align-self: center;
  margin-top: calc(40px + 16px) !important;
  min-width: 0;
  padding: 8px;
}
.h1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-weight: 700;
  font-size: 3em;
  letter-spacing: -2px;
  color: #ffffff;
  padding-left: 0px;
  text-align: center;
  align-self: center;
  line-height: 1em;
  min-width: 0;
}
.text__nPoJu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 700px;
  color: #ffffff;
  font-size: 1em;
  font-weight: 400;
  text-align: center;
  align-self: center;
  line-height: 1.3;
  min-width: 0;
  margin: 15px 0px 0px;
}
.freeBox__vnLb9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 1200px;
  height: 100%;
  max-width: 100%;
  background: none;
  flex-wrap: nowrap;
  align-self: center;
  min-height: 700px;
  align-content: stretch;
  padding: 0px;
}
.citySummary {
  display: flex;
  position: relative;
  width: auto;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  align-self: center;
  flex-grow: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.citySummary > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
  --plsmc-rc-col-gap: 10px;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.citySummary > :global(.__wab_flex-container) > *,
.citySummary > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.citySummary > :global(.__wab_flex-container) > picture > img,
.citySummary
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
  margin-top: 10px;
}
.citySummary > :global(.__wab_flex-container) > :nth-child(4n + 1) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.citySummary > :global(.__wab_flex-container) > :nth-child(4n + 2) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.citySummary > :global(.__wab_flex-container) > :nth-child(4n + 3) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.citySummary > :global(.__wab_flex-container) > :nth-child(4n + 4) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .citySummary {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .citySummary > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .citySummary > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column___7YXkv {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.radialSummary__znY0:global(.__wab_instance) {
  position: relative;
  left: auto;
  top: auto;
}
.text__jAbHp {
  color: #16e71f;
}
.column__kL6Gs {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.radialSummary__zuq84:global(.__wab_instance) {
  position: relative;
  left: auto;
  top: auto;
}
.column__izsMg {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.radialSummary__rbSM:global(.__wab_instance) {
  position: relative;
  left: auto;
  top: auto;
}
.column__xiCdZ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.radialSummary__q6ZjC:global(.__wab_instance) {
  position: relative;
  left: auto;
  top: auto;
}
.column__irC1 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.radialSummary__ePjZn:global(.__wab_instance) {
  position: relative;
  left: auto;
  top: auto;
}
.column___4LBd {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.radialSummary___1K910:global(.__wab_instance) {
  position: relative;
  left: auto;
  top: auto;
}
.column__kzPF {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.radialSummary__qCobv:global(.__wab_instance) {
  position: relative;
  left: auto;
  top: auto;
}
.freeBox__r6KIy {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
}
.footer:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
