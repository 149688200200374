.root {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: url("./images/lines3Png.png") top 50% right -20px / cover repeat, #161620;
  min-width: 0;
  min-height: 0;
  padding: 20px 24px 96px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 16px;
}
.navBar2:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .navBar2:global(.__wab_instance) {
    position: relative;
  }
}
.titlesSection {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 1200px;
  background: none;
  margin-top: calc(21px + 16px) !important;
  min-width: 0;
  padding: 0px;
}
.columns__mKt1U {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__mKt1U > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 7 / 12);
}
.columns__mKt1U > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 5 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__mKt1U {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__mKt1U > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__xIKhH {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__csmAi {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.h1__jOkFo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-weight: 700;
  font-size: 2em;
  letter-spacing: 0px;
  color: #ffffff;
  padding-left: 0px;
  text-align: left;
  align-self: center;
  line-height: 1em;
  min-width: 0;
}
.text__vhiH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-weight: 400;
  text-align: left;
  align-self: center;
  min-width: 0;
  margin: 8px 0px 0px;
}
.radialSummaryCol {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.radialSummary:global(.__wab_instance) {
  position: relative;
}
.firstSection {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 1200px;
  height: auto;
  max-width: 100%;
  background: none;
  padding: 0px;
}
.columns__xFFbg {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__xFFbg > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
  --plsmc-rc-col-gap: 20px;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.columns__xFFbg > :global(.__wab_flex-container) > *,
.columns__xFFbg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__xFFbg > :global(.__wab_flex-container) > picture > img,
.columns__xFFbg
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
  margin-top: 10px;
}
.columns__xFFbg > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.columns__xFFbg > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 8 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__xFFbg {
    width: 100%;
    height: auto;
    flex-direction: row;
    padding-left: 0px;
    padding-right: 0px;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__xFFbg > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__xFFbg > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__l1Ll7 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.returnOptions {
  box-shadow: 0px 4px 16px 6px #00000033;
  background: #1f2124;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 10px;
  padding: 8px;
  border: 2px solid #52d6f3;
}
.returnCityOverviewButton:global(.__wab_instance) {
  position: relative;
  display: none;
}
.returnToListOfCitiesButton:global(.__wab_instance) {
  position: relative;
}
.brandList {
  box-shadow: 0px 4px 16px 6px #00000033;
  background: #1f2124;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 20px;
  min-width: 0;
  border-radius: 10px;
  padding: 8px;
  border: 2px solid #52d6f3;
}
.brandTitle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.freeBox__k2Dyw {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.h1__fWhf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-size: 1.5em;
  letter-spacing: -1px;
  font-weight: 700;
  min-width: 0;
}
.freeBox__ergsl {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 0px;
}
.brandListCol {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.brandListCol > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
  --plsmc-rc-col-gap: 10px;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.brandListCol > :global(.__wab_flex-container) > *,
.brandListCol > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.brandListCol > :global(.__wab_flex-container) > picture > img,
.brandListCol
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
  margin-top: 5px;
}
.brandListCol > :global(.__wab_flex-container) > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .brandListCol {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .brandListCol > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .brandListCol > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.brand {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 4px 8px;
}
.brandName__xsRD:global(.__wab_instance) {
  position: relative;
}
.brandName__llTp5:global(.__wab_instance) {
  position: relative;
}
.brandName___98AM:global(.__wab_instance) {
  position: relative;
}
.brandName__r4Yq0:global(.__wab_instance) {
  position: relative;
}
.brandName__gz24:global(.__wab_instance) {
  position: relative;
}
.brandName__jpO0:global(.__wab_instance) {
  position: relative;
}
.column___5YKaH {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 0px;
}
.freeBox__ksxg5 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 0px;
}
.freeBox__mpKa0 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__mpKa0 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.brandDetailRight {
  box-shadow: 0px 4px 16px 6px #00000033;
  background: #1f2124;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 10px;
  padding: 8px;
  border: 2px solid #52d6f3;
}
.brandsSummaryTitle:global(.__wab_instance) {
  position: relative;
  align-self: center;
}
.freeBox__cu9VB {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 0px;
}
.columns__zG7PF {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__zG7PF > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
  --plsmc-rc-col-gap: 20px;
}
.columns__zG7PF > :global(.__wab_flex-container) > *,
.columns__zG7PF > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__zG7PF > :global(.__wab_flex-container) > picture > img,
.columns__zG7PF
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
}
.columns__zG7PF > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns__zG7PF > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__zG7PF {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__zG7PF > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__zG7PF > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__p2Yb4 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  padding: 8px;
}
.companyDescriptionComp:global(.__wab_instance) {
  position: relative;
}
.column__efywb {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 0px 8px 8px;
}
.radialAndDatapoints {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 0px;
}
.freeBox__nW1H {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__nW1H {
    margin-top: 15px;
    padding-left: 0px;
    padding-right: 0px;
  }
}
.radialChartCompanySentiment:global(.__wab_instance) {
  position: relative;
}
.img__rtNlc {
  object-fit: contain;
  max-width: 100%;
  position: relative;
}
.img__rtNlc > picture > img {
  object-fit: contain;
}
.freeBox__uVb4 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  align-self: center;
  min-width: 0;
  padding: 8px 10px 0px;
}
.sentimentsAnalyzed:global(.__wab_instance) {
  position: relative;
  align-self: center;
  display: none;
}
.freeBox__ymo48 {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  display: none;
  padding: 0px 8px 8px;
}
.text__otZfp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-weight: 800;
  font-size: 1.2em;
  line-height: 1.1em;
  min-width: 0;
}
.text__np2Ef {
  padding-top: 10px;
}
.freeBox__mIdzg {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  margin-top: 10px;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__mIdzg {
    margin-top: 10px;
    padding-left: 2px;
    padding-right: 2px;
  }
}
.barChart {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-height: 300px;
  min-width: 200px;
  padding: 15px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .barChart {
    padding: 5px 0px;
  }
}
.columns__n6WUy {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__n6WUy > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.columns__n6WUy > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 8 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__n6WUy {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__n6WUy > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__hpz3U {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  padding: 8px;
}
.text__gyCrK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-weight: 800;
  font-size: 1.2em;
  line-height: 1.1em;
  min-width: 0;
}
.text__fDvW {
  margin-top: 22px;
  font-size: 0.9em;
}
.text___8W5Bu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-weight: 800;
  font-size: 1.2em;
  min-width: 0;
  display: none;
}
.column__wrdmh {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.brandPerceptionBarChart:global(.__wab_instance) {
  position: relative;
  width: auto;
  height: 350px;
  align-self: center;
  flex-shrink: 0;
}
.img__pallF {
  object-fit: contain;
  max-width: 100%;
  position: relative;
}
.img__pallF > picture > img {
  object-fit: contain;
}
.callToActionBanner:global(.__wab_instance) {
  position: relative;
  margin-top: calc(60px + 16px) !important;
}
@media (min-width: 0px) and (max-width: 768px) {
  .callToActionBanner:global(.__wab_instance) {
    margin-top: calc(60px + 16px) !important;
  }
}
.footer:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
