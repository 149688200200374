.root {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: auto;
  height: auto;
  max-width: 100%;
  background: var(--token-KG4if5sFq);
  color: #ffffff;
  cursor: pointer;
  position: relative;
  transition-property: all;
  transition-duration: 0.3s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  border-radius: 4px;
  padding: 6px 14px;
}
.rootsubscribed {
  background: var(--token-KG4if5sFq);
}
.root:hover {
  color: #ffffff;
  background: linear-gradient(80deg, #136f27 48%, #1ca539 100%);
}
.text__on9JH {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
}
.text__ekYsH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 9px;
  min-width: 0;
  display: none;
}
.textsubscribed__ekYsHjAp7A {
  display: block;
}
