.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  background: url("./images/lines3Png.png") top 50% right 0% / contain no-repeat,
    #16161f;
  min-width: 0;
  min-height: 0;
  padding: 20px 24px 96px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root {
    background: #16161f;
  }
}
.navBar2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .navBar2:global(.__wab_instance) {
    height: auto;
  }
}
.freeBox__jc5O4 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  max-width: 1400px;
  background: none;
  margin-bottom: 80px;
  min-height: 95vh;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__jc5O4 {
    height: auto;
  }
}
.freeBox__iHzTn {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 100%;
  background: none;
  flex-grow: 0;
  min-width: 0;
}
.columns {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  margin-top: 93px;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 40px);
  width: calc(100% + 40px);
  --plsmc-rc-col-gap: 40px;
}
.columns > :global(.__wab_flex-container) > *,
.columns > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns > :global(.__wab_flex-container) > picture > img,
.columns
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 40px;
}
.columns > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
    margin-top: calc(0px - 80px);
    height: calc(100% + 80px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns > :global(.__wab_flex-container) > *,
  .columns > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns > :global(.__wab_flex-container) > picture > img,
  .columns
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 80px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__ya90V {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.text__oynTh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 2em;
  font-weight: 900;
  line-height: 1.2;
  min-width: 0;
}
.text___6KkCg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-weight: 300;
  padding-top: 0px;
  padding-left: 0px;
  margin-top: 27px;
  min-width: 0;
}
.column__xtGcc {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.form:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.footer:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
