.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  background: none;
  position: sticky;
  border-top-color: #595959;
  border-right-color: #595959;
  border-bottom-color: #ffffff;
  border-left-color: #595959;
  min-width: 0;
  padding: 7px 11px;
  border-width: 1px;
  border-style: none;
}
.rootcolor_dark {
  background: #232320;
  border-color: #717069;
}
.root:hover {
  border-color: #c8c7c1;
}
.root:focus-within {
  box-shadow: 0px 0px 0px 3px #96c7f2;
  outline: none;
  border-radius: 6px;
}
.root___focusVisibleWithin {
  box-shadow: 0px 0px 0px 3px #96c7f2;
  outline: none;
  border-radius: 6px;
}
.input {
  width: 100%;
  left: auto;
  top: auto;
  background: #ffffff00;
  font-size: 1em;
  color: #333333;
  min-width: 0;
  padding: 0px;
  border-width: 1px;
  border-style: none;
}
.inputisDisabled {
  cursor: not-allowed;
}
.inputcolor_dark {
  color: #ffffff;
}
.root:focus-within .input {
  outline: none;
}
.root .input___focusVisibleWithin {
  outline: none;
}
.root .input:focus {
  outline: none;
}
.root:focus-within .input:focus {
  outline: none;
}
.root .input::placeholder {
  color: #706f6c;
}
.rootcolor_dark .inputcolor_dark::placeholder {
  color: #c8c7c1;
}
.root:focus-within .input::placeholder {
  outline: none;
}
