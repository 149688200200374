.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  background: url("./images/lines3Png.png") top 50% right 2% / cover repeat, #16161f;
  min-width: 0;
  min-height: 0;
  padding: 20px 0px 96px;
}
.navBar2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.hero {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 100%;
  background: none;
  margin-top: 40px;
  margin-bottom: 60px;
  min-height: 70vh;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .hero {
    height: auto;
    margin-top: 90px;
  }
}
.columns__jeeql {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  max-width: 1100px;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__jeeql > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 30px);
  width: calc(100% + 30px);
  --plsmc-rc-col-gap: 30px;
}
.columns__jeeql > :global(.__wab_flex-container) > *,
.columns__jeeql > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__jeeql > :global(.__wab_flex-container) > picture > img,
.columns__jeeql
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 30px;
}
.columns__jeeql > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns__jeeql > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__jeeql {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__jeeql > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap-reverse;
    min-width: 0;
    margin-top: calc(0px - 20px);
    height: calc(100% + 20px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__jeeql > :global(.__wab_flex-container) > *,
  .columns__jeeql > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__jeeql > :global(.__wab_flex-container) > picture > img,
  .columns__jeeql
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 20px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__jeeql > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column___1Reei {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__li6SB {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.text__dCqqU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-size: 2em;
  font-weight: 900;
  line-height: 1.2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__dCqqU {
    text-align: center;
  }
}
.text___6TP3Z {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-weight: 400;
  margin-top: 15px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text___6TP3Z {
    text-align: center;
  }
}
.column__qhxCn {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__nu0Tq {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 500px;
  height: auto;
  object-position: left center;
  border-radius: 500px;
  border: 1px solid #dae5ff;
}
.img__nu0Tq > picture > img {
  object-fit: cover;
  object-position: left center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__nu0Tq {
    height: auto;
    width: 100%;
    max-width: 90%;
    min-width: 0;
  }
}
.freeBox__eBEbA {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__eBEbA {
    display: none;
  }
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.smallBiz {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 900px;
  background: none;
  margin-top: 40px;
  min-width: 0;
  padding: 30px;
}
.freeBox__hjLPc {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #429d1e;
  min-width: 0;
  border-radius: 20px;
  padding: 8px;
}
.columns__qawIt {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__qawIt > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns__qawIt > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__qawIt {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__qawIt > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__nxey7 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__hQqox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.text__oPwTt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 0.8em;
  font-weight: 400;
  min-width: 0;
}
.text__uNe2O {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 1.5em;
  font-weight: 900;
  margin-top: 31px;
  min-width: 0;
}
.text__cAdPj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 1em;
  font-weight: 400;
  margin-top: 10px;
  min-width: 0;
}
.column__mnDht {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.form:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.academic {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 1200px;
  background: none;
  margin-top: 99px;
  margin-bottom: 40px;
  min-width: 0;
  padding: 8px;
}
.columns__qJhmS {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__qJhmS > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
}
.columns__qJhmS > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns__qJhmS > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__qJhmS {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__qJhmS > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap-reverse;
    min-width: 0;
    margin-top: calc(0px - 20px);
    height: calc(100% + 20px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__qJhmS > :global(.__wab_flex-container) > *,
  .columns__qJhmS > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__qJhmS > :global(.__wab_flex-container) > picture > img,
  .columns__qJhmS
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 20px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__qJhmS > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__vuVmN {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__tLv3 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.text__rVhOw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 1.5em;
  font-weight: 900;
  min-width: 0;
}
.text___4DCm3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 1em;
  font-weight: 400;
  margin-top: 5px;
  min-width: 0;
}
.freeBox__cz6V4 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  margin-top: 30px;
  min-width: 0;
}
.greenButton {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: auto;
  height: auto;
  max-width: 100%;
  background: var(--token-KG4if5sFq);
  align-self: flex-start;
  color: #ffffff;
  cursor: pointer;
  border-radius: 4px;
  padding: 6px 14px;
}
.root .greenButton:hover {
  color: #ffffff;
}
.text__aguFp {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
}
.column__mGct2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column__mGct2 {
    padding: 20px;
  }
}
.img__aXIb6 {
  position: relative;
  object-fit: cover;
  max-width: 400px;
}
.img__aXIb6 > picture > img {
  object-fit: cover;
}
.contactDiv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__wDlwi {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.footer:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
