.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  position: relative;
  min-width: 0;
  padding: 8px;
}
.freeBox___4PnBf {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  align-self: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__fep6G {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  align-self: center;
  flex-wrap: wrap;
  align-content: center;
  min-width: 0;
  padding: 0px;
}
.h1 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  padding-right: 20px;
  align-self: center;
  display: flex;
  flex-direction: row;
  font-size: 1.2em;
}
.slotTargetBrandName {
  font-size: 1.5em;
  font-weight: 700;
  color: #ffffff;
  text-align: left;
  letter-spacing: -1px;
}
.freeBox__gYl2T {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 0px;
}
.locationProp {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: auto;
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.slotTargetCityIn {
  font-size: 1em;
  font-weight: 400;
  color: #e0e0e0;
}
.locationsStr2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #e0e0e0;
  font-size: 1em;
  align-self: auto;
  font-weight: 400;
  min-width: 0;
}
.freeBox__tan4D {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 0px;
}
.freeBox__jTqVd {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  align-self: flex-end;
  min-width: 0;
  padding: 0px;
}
.vertical {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-self: auto;
  align-items: center;
  justify-content: center;
}
.slotTargetAgggScore {
  font-size: 1.6em;
  font-weight: 900;
  color: #1affff;
  letter-spacing: normal;
}
.hundred {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #c2c2c2;
  font-weight: 400;
  font-size: 1.1em;
  letter-spacing: normal;
}
