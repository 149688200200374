.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  position: relative;
  min-width: 0;
  padding: 8px;
}
.freeBox__jn3Q3 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.freeBox__c4Wli {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 0px;
}
.freeBox__iDn5G {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 0px;
}
.freeBox__jBlTr {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  padding-right: 6px;
  display: flex;
  flex-direction: row;
}
.text__oibUn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-weight: 400;
  color: #d0d0d0;
  font-size: 0.7em;
  min-width: 0;
}
.freeBox__dF5P7 {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  display: none;
  padding: 0px;
}
.freeBox__gTKx7 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 0px;
}
.freeBox__krRj {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  padding-right: 6px;
  display: flex;
  flex-direction: row;
}
.text__aYlPy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-weight: 400;
  color: #d0d0d0;
  font-size: 0.7em;
  min-width: 0;
}
