.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  position: relative;
  min-width: 0;
  padding: 0px;
}
.img__qAlhj {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  align-self: auto;
}
.img__qAlhj > picture > img {
  object-fit: cover;
}
