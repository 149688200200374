.root {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-height: 70vh;
  min-width: 0;
  padding: 0px;
}
.reveal:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-self: center;
  min-width: 0;
}
.freeBox__cEoG {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  min-width: 0;
  padding: 8px;
}
.text___4A3K5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  font-size: 1.1em;
  min-width: 0;
}
.freeBox___0EGX {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: center;
  margin-top: 52px;
  min-width: 0;
  padding: 8px;
}
.greenButton4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: auto;
  height: auto;
  max-width: 100%;
  background: linear-gradient(110deg, #136f27 23%, #136f27 100%);
  color: #ffffff;
  transition-property: all;
  transition-duration: 0.3s;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  border-radius: 4px;
  padding: 6px 14px;
}
.root .greenButton4:hover {
  color: #ffffff;
  background: linear-gradient(110deg, #136f27 23%, #11922d 100%);
  transform: translateX(0px) translateY(-2px) translateZ(0px);
}
.text__mSvz1 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 1.2em;
}
