.root {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: url("./images/lines3Png.png") top 50% right -20px / cover no-repeat,
    #161620;
  min-width: 0;
  min-height: 0;
  padding: 20px 24px 96px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 16px;
}
.navBar2:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .navBar2:global(.__wab_instance) {
    position: relative;
  }
}
.titlesSection {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 1200px;
  background: none;
  margin-top: calc(21px + 16px) !important;
  min-width: 0;
  padding: 0px;
}
.columns__wNsvH {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__wNsvH > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 7 / 12);
}
.columns__wNsvH > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 5 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__wNsvH {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__wNsvH > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column___6OUzt {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox___0CAgP {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.h1__bGsY7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-weight: 700;
  font-size: 2em;
  letter-spacing: 0px;
  color: #ffffff;
  padding-left: 0px;
  text-align: left;
  align-self: center;
  line-height: 1em;
  min-width: 0;
}
.text__pyqfz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-weight: 400;
  text-align: left;
  align-self: center;
  font-size: 16px;
  min-width: 0;
  margin: 8px 0px 0px;
}
.radialSummaryCol {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.radialSummary:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.firstSection {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 1200px;
  height: auto;
  max-width: 100%;
  background: none;
  padding: 0px;
}
.columns__h3Z57 {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__h3Z57 > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
  --plsmc-rc-col-gap: 20px;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.columns__h3Z57 > :global(.__wab_flex-container) > *,
.columns__h3Z57 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__h3Z57 > :global(.__wab_flex-container) > picture > img,
.columns__h3Z57
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
  margin-top: 10px;
}
.columns__h3Z57 > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns__h3Z57 > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__h3Z57 {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__h3Z57 > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__h3Z57 > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__cR4Rj {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.returnOptions {
  box-shadow: 0px 4px 16px 6px #00000033;
  background: #1f2124;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 10px;
  padding: 8px;
  border: 2px solid #52d6f3;
}
.returnToSection:global(.__wab_instance) {
  position: relative;
  display: none;
}
.returnCityOverviewButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.returnToListOfCitiesButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.aggRankingsBox {
  box-shadow: 0px 4px 16px 6px #00000033;
  background: #1f2124;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 20px;
  min-width: 0;
  border-radius: 10px;
  padding: 8px;
  border: 2px solid #52d6f3;
}
.aggRankingTitle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.freeBox___1Erx {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.h1__aCBa2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-size: 1.5em;
  letter-spacing: -1px;
  font-weight: 700;
  line-height: 1.1;
  min-width: 0;
}
.text___63Q6A {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffffcc;
  font-size: 0.9em;
  font-weight: 400;
  text-align: left;
  align-self: center;
  line-height: 0.9em;
  font-style: italic;
  min-width: 0;
  margin: 8px 0px 0px;
}
.freeBox___6JrVd {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #1af6f6;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}
.horizontalCol {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  margin-top: 30px;
  min-width: 0;
  padding: 8px;
}
.aggRankingList {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 0px;
}
.aggRankingList > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
  --plsmc-rc-col-gap: 10px;
  margin-top: calc(0px - 15px);
  height: calc(100% + 15px);
}
.aggRankingList > :global(.__wab_flex-container) > *,
.aggRankingList > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.aggRankingList > :global(.__wab_flex-container) > picture > img,
.aggRankingList
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
  margin-top: 15px;
}
.aggRankingList > :global(.__wab_flex-container) > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .aggRankingList {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .aggRankingList > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .aggRankingList > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.oneBrandCol {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 0px;
}
.aggBrandInsight___3J7Xr:global(.__wab_instance) {
  position: relative;
}
.aggBrandInsight__qc5Ct:global(.__wab_instance) {
  position: relative;
}
.aggBrandInsight__puL:global(.__wab_instance) {
  position: relative;
}
.text__hrBxA {
  color: #1affff;
}
.aggBrandInsight__shO8K:global(.__wab_instance) {
  position: relative;
}
.aggBrandInsight__kKdE:global(.__wab_instance) {
  position: relative;
}
.aggBrandInsight__gbymE:global(.__wab_instance) {
  position: relative;
}
.fullListCol {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.viewFullListButton:global(.__wab_instance) {
  position: relative;
}
.column__wtA4S {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.column__wtA4S > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.column__wtA4S > :global(.__wab_flex-container) > *,
.column__wtA4S > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__wtA4S > :global(.__wab_flex-container) > picture > img,
.column__wtA4S
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.cityMapStack {
  box-shadow: 0px 4px 16px 6px #00000033;
  background: #1f2124;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-height: 400px;
  min-width: 0;
  border-radius: 10px;
  padding: 0px;
  border: 2px solid #52d6f3;
}
@media (min-width: 0px) and (max-width: 768px) {
  .cityMapStack {
    padding: 0px;
  }
}
.mapImageCity:global(.__wab_instance) {
  position: relative;
}
.freeBox___9YHpG {
  box-shadow: 0px 4px 16px 6px #00000033;
  background: #1f2124;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 10px;
  padding: 8px;
  border: 2px solid #52d6f3;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___9YHpG {
    margin-top: calc(38px + 20px) !important;
    padding: 0px;
  }
}
.newsTitle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 0px;
}
.freeBox__dnwDz {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.freeBox__fMwlx {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.h1__pyh1D {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-size: 1.5em;
  letter-spacing: -1px;
  font-weight: 700;
  text-align: left;
  margin-right: 10px;
}
.twitterNewsTop {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.twitterList {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  margin-top: 15px;
  min-width: 0;
  padding: 0px;
}
.columns__bi1Bc {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 0px;
}
.columns__bi1Bc > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
  --plsmc-rc-col-gap: 10px;
  margin-top: calc(0px - 15px);
  height: calc(100% + 15px);
}
.columns__bi1Bc > :global(.__wab_flex-container) > *,
.columns__bi1Bc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__bi1Bc > :global(.__wab_flex-container) > picture > img,
.columns__bi1Bc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
  margin-top: 15px;
}
.columns__bi1Bc > :global(.__wab_flex-container) > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__bi1Bc {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__bi1Bc > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__bi1Bc > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.newsItemCol {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  padding: 0px;
}
.tweetIndividual__hTthX:global(.__wab_instance) {
  position: relative;
  align-self: flex-start;
}
.tweetIndividual__y4Gna:global(.__wab_instance) {
  position: relative;
  align-self: flex-start;
}
.callToActionBanner:global(.__wab_instance) {
  position: relative;
  margin-top: calc(60px + 16px) !important;
  margin-bottom: 90px;
}
.freeBox__nls2C {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 1200px;
  background: none;
  margin-top: calc(47px + 16px) !important;
  min-width: 0;
  display: none;
  padding: 8px;
}
.twitterNewsTop2 {
  box-shadow: 0px 4px 16px 6px #00000033;
  background: #1f2124;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 10px;
  padding: 8px;
  border: 2px solid #52d6f3;
}
.freeBox__zss3D {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.newsTitle2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.freeBox__r63B {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 8px;
}
.freeBox__aMbZi {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  align-self: flex-start;
  min-width: 0;
  padding: 0px;
}
.h1__xaqrC {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-size: 1.5em;
  letter-spacing: 0px;
  font-weight: 700;
  text-align: left;
  margin-right: 10px;
}
.twitterList4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  margin-top: 15px;
  min-width: 0;
  padding: 8px;
}
.twitterList2 {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 0px;
}
.twitterList2 > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
  --plsmc-rc-col-gap: 10px;
  margin-top: calc(0px - 45px);
  height: calc(100% + 45px);
}
.twitterList2 > :global(.__wab_flex-container) > *,
.twitterList2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.twitterList2 > :global(.__wab_flex-container) > picture > img,
.twitterList2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
  margin-top: 45px;
}
.twitterList2 > :global(.__wab_flex-container) > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .twitterList2 {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .twitterList2 > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .twitterList2 > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 0px;
}
.tweetIndividual___0Bqh0:global(.__wab_instance) {
  position: relative;
}
.tweetIndividual__k0ZCq:global(.__wab_instance) {
  position: relative;
}
.tweetIndividual__gj2G0:global(.__wab_instance) {
  position: relative;
}
.tweetIndividual___3KS:global(.__wab_instance) {
  position: relative;
}
.tweetIndividual__shq4J:global(.__wab_instance) {
  position: relative;
}
.tweetIndividual___2Jt5O:global(.__wab_instance) {
  position: relative;
}
.footer:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
