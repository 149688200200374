.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  position: relative;
  cursor: pointer;
  min-width: 0;
  padding: 8px;
  border-width: 0px;
}
.freeBox {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  padding-right: 14px;
  display: flex;
  flex-direction: row;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 15px);
  width: calc(100% + 15px);
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 15px;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-size: 1em;
  min-width: 0;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #00f0f2;
  width: auto;
  min-width: 30px;
  min-height: 30px;
  height: 1em;
}
