.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  position: relative;
  min-width: 0;
  padding: 8px;
  border-width: 1px;
  border-style: none;
}
.link {
  position: relative;
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  flex-grow: 1;
}
.freeBox__d1F {
  display: flex;
  position: relative;
  margin-right: 20px;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #2dff35;
  width: 1.3em;
  height: 1.3em;
  margin-right: 0;
  flex-shrink: 0;
}
.freeBox__y80RG {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-size: 0.9em;
  min-width: 0;
}
